import React from "react";

const NewHomePage = () => {
    return <>
        <header>header</header>
        <subnav>subnav</subnav>
        <p>something fun</p>
        <div>spelled out resume</div>
    </>
}

export { NewHomePage };